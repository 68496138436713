<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('views.promo_codes.group')}}</h4>
            <div class="form-group">
              <select v-model="selectedGroup" class="form-control form-control-sm">
                <option v-for="item in groups" :key="item.id" :value="item.id">
                  {{item.codename}} ({{ item.description }})
                </option>
              </select>
            </div>

            <div class="row">
              <div class="col-12 text-right">
                <div @click="showAddGroupPopup" class="btn btn-sm btn-success">
                  <i class="fa fa-plus"></i>
                  {{ $t('views.promo_codes.group') }}
                </div>
                <div v-show="selectedGroup > 0" @click="showDeleteGroupPopup" class="btn btn-sm btn-danger ml-2">
                  <i class="fa fa-times"></i>
                  {{ $t('views.promo_codes.group') }}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">

            <div v-if="selectedGroup > 0" class="row">
              <div class="col-12 text-right">
                <div @click="showAddPromocodePopup" class="btn btn-sm btn-success">
                  <i class="fa fa-plus"></i>
                  {{ $t('views.promo_codes.promo_code') }}
                </div>
              </div>
            </div>

            <div v-if="selectedGroup > 0" class="row mt-3">
              <div class="col-12">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>{{$t('views.promo_codes.promo_code_name')}}</th>
                      <th>{{$t('views.promo_codes.bonus_sum')}}</th>
                      <th>{{$t('popularWords.description')}}</th>
                      <th>{{$t('views.promo_codes.is_work_after_first_pay')}}</th>
                      <th>{{$t('views.promo_codes.start_datetime')}}</th>
                      <th>{{$t('views.promo_codes.end_datetime')}}</th>
                      <th>{{$t('views.promo_codes.activate_count')}}</th>
                      <th>{{$t('popularWords.actions')}}</th>
                    </tr>
                  </thead>
                  <tbody class="mt-3" v-for="(item, index) in promoCodes" :key="index">
                    <tr>
                      <td>{{item.codename}}</td>
                      <td>{{item.bonus_sum}}</td>
                      <td>{{item.description}}</td>
                      <td>
                        <span v-if="item.is_work_after_first_pay == 1">{{ $t('popularWords.yes') }}</span>
                        <span v-if="item.is_work_after_first_pay == 0">{{ $t('popularWords.no') }}</span>
                      </td>
                      <td>{{phpDatetimeToString(item.start_dt)}}</td>
                      <td>{{phpDatetimeToString(item.end_dt)}}</td>
                      <td>{{item.activate_count}}</td>
                      <td>
                        <div @click="showDeletePromocodePopup(item.id)" class="btn btn-sm btn-danger">
                          <i class="fa fa-times"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="selectedGroup == 0" class="row mt-3">
              <div class="col-12">
                <div class="alert alert-sm alert-info">
                  {{ $t('views.promo_codes.dont_select_group') }}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <popup
      :closeButton="addGroupPopup.closeButton"
      :actionButton="addGroupPopup.actionButton"
      :actionClass="addGroupPopup.actionClass"
      :show="addGroupPopup.show"
      @closePopup="closeAddGroupPopup"
      @actionPopup="addGroupPopupSubmit"
    >
      <div slot="header">{{$t('views.promo_codes.add_group')}}</div>
      <div slot="body">
        <form class="needs-validation">
          <div class="form-group">
            <label for="newGroup_codename">{{ $t('views.promo_codes.group_name') }}</label>
            <input
                id="newGroup_codename"
                v-model="newGroup.codename"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': submitted && $v.newGroup.codename.$error }"
            />
            <small class="form-text text-muted">
              {{ $t('views.promo_codes.in_latin') }}
            </small>
            <div v-if="submitted && $v.newGroup.codename.$error" class="invalid-feedback">
              <span v-if="!$v.newGroup.codename.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newGroup.codename.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newGroup.codename.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 40
              </span>
            </div>
          </div>
          <div class="form-group">
            <label for="newGroup_description">{{ $t('popularWords.description') }}</label>
            <textarea 
              rows="2"
              v-model="newGroup.description"
              class="form-control form-control-sm"
              :class="{ 'is-invalid': submitted && $v.newGroup.description.$error }"
            ></textarea>
            <div v-if="submitted && $v.newGroup.description.$error" class="invalid-feedback">
              <span v-if="!$v.newGroup.description.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newGroup.description.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newGroup.description.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 255
              </span>
            </div>
          </div>
        </form>
      </div>
    </popup>

    <popup
      :closeButton="deleteGroupPopup.closeButton"
      :actionButton="deleteGroupPopup.actionButton"
      :actionClass="deleteGroupPopup.actionClass"
      :show="deleteGroupPopup.show"
      @closePopup="closeDeleteGroupPopup"
      @actionPopup="deleteGroupPopupSubmit"
    >
      <div slot="header">{{$t('views.promo_codes.delete_group')}}</div>
      <div slot="body">
        {{$t('views.promo_codes.delete_group_text')}}
      </div>
    </popup>


    <popup
      :closeButton="addPromocodePopup.closeButton"
      :actionButton="addPromocodePopup.actionButton"
      :actionClass="addPromocodePopup.actionClass"
      :show="addPromocodePopup.show"
      @closePopup="closeAddPromocodePopup"
      @actionPopup="addPromocodePopupSubmit"
    >
      <div slot="header">{{$t('views.promo_codes.add_promocode')}}</div>
      <div slot="body">
        <form class="needs-validation">
          <div class="form-group">
            <label for="newPromocode_codename">{{ $t('views.promo_codes.promo_code_name') }}</label>
            <input
                id="newPromocode_codename"
                v-model="newPromocode.codename"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': submitted && $v.newPromocode.codename.$error }"
            />
            <small class="form-text text-muted">
              {{ $t('views.promo_codes.in_latin') }}
            </small>
            <div v-if="submitted && $v.newPromocode.codename.$error" class="invalid-feedback">
              <span v-if="!$v.newPromocode.codename.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newPromocode.codename.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newPromocode.codename.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 40
              </span>
            </div>
          </div>
          <div class="form-group">
            <label for="newPromocode_bonus_sum">{{ $t('views.promo_codes.bonus_sum') }}</label>
            <input
                id="newPromocode_bonus_sum"
                v-model="newPromocode.bonus_sum"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': submitted && $v.newPromocode.bonus_sum.$error }"
            />
            <div v-if="submitted && $v.newPromocode.bonus_sum.$error" class="invalid-feedback">
              <span v-if="!$v.newPromocode.bonus_sum.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newPromocode.bonus_sum.minValue">
                {{ $t('validatePhrases.minValue') }} - 1
              </span>
              <span v-if="!$v.newPromocode.bonus_sum.maxValue">
                {{ $t('validatePhrases.maxValue') }} - 50000
              </span>
            </div>
          </div>
          <div class="form-group">
            <label for="newPromocode_description">{{ $t('popularWords.description') }}</label>
            <textarea 
              rows="2"
              v-model="newPromocode.description"
              class="form-control form-control-sm"
              :class="{ 'is-invalid': submitted && $v.newPromocode.description.$error }"
            ></textarea>
            <div v-if="submitted && $v.newPromocode.description.$error" class="invalid-feedback">
              <span v-if="!$v.newPromocode.description.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newPromocode.description.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newPromocode.description.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 255
              </span>
            </div>
          </div>
          <div class="form-group">
            <label for="newPromocode_is_work_after_first_pay">{{$t('views.promo_codes.is_work_after_first_pay')}}</label>
            <select v-model="newPromocode.is_work_after_first_pay" class="form-control form-control-sm">
              <option value="0">{{ $t('popularWords.no') }}</option>
              <option value="1">{{ $t('popularWords.yes') }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="newPromocode_start_dt">{{$t('views.promo_codes.start_datetime')}}</label>
            <date-picker v-model="newPromocode.start_dt" append-to-body :lang="pickerLang" :format="'DD MMM YYYY'"
              :type="'date'" confirm class="mr-0 form-control-sm">
            </date-picker>
            <div v-if="submitted && $v.newPromocode.start_dt.$error" class="invalid-feedback">
              <span v-if="!$v.newPromocode.start_dt.required">{{ $t('validatePhrases.requiredField') }}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="newPromocode_end_dt">{{$t('views.promo_codes.end_datetime')}}</label>
            <date-picker v-model="newPromocode.end_dt" append-to-body :lang="pickerLang" :format="'DD MMM YYYY'"
              :type="'date'" confirm class="mr-0 form-control-sm">
            </date-picker>
            <div v-if="submitted && $v.newPromocode.end_dt.$error" class="invalid-feedback">
              <span v-if="!$v.newPromocode.end_dt.required">{{ $t('validatePhrases.requiredField') }}</span>
            </div>
          </div>
        </form>
      </div>
    </popup>


    <popup
      :closeButton="deletePromocodePopup.closeButton"
      :actionButton="deletePromocodePopup.actionButton"
      :actionClass="deletePromocodePopup.actionClass"
      :show="deletePromocodePopup.show"
      @closePopup="closeDeletePromocodePopup"
      @actionPopup="deletePromocodePopupSubmit"
    >
      <div slot="header">{{$t('views.promo_codes.delete_promocode')}}</div>
      <div slot="body">
        {{$t('views.promo_codes.delete_promocode_text')}}
      </div>
    </popup>


  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";

import axios from "axios";
import Popup from '../../../components/Popup.vue';
import Formatter from "../../../components/libraries/Formatter";

const formatter = new Formatter();

import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue
} from "vuelidate/lib/validators";


export default {
  components: {
    Layout,
    PageHeader,
    Popup,
    DatePicker
},
  data() {
    return {
      submitted: false,

      groups: [],
      selectedGroup: 0,

      promoCodes: [],

      addGroupPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      deleteGroupPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      newGroup: {
        codename: '',
        description: ''
      },

      addPromocodePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      deletePromocodePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      deletedPromocodeId: 0,
      newPromocode: {
        codename: '',
        bonus_sum: 0,
        description: '',
        is_work_after_first_pay: 0,
        start_dt: '',
        end_dt: ''
      },

      pickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: this.$t('datepicker.months'),
          weekdaysMin: this.$t('datepicker.weekdaysMin'),
          monthsShort: this.$t('datepicker.monthsShort'),
        },
        monthBeforeYear: false,
      },

      title: this.$t('menuitems.management.list.promo_codes'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.promo_codes'),
          active: true
        }
      ],
    };
  },
  validations: {
    newGroup: {
      codename: { required, minLength: minLength(1), maxLength: maxLength(40) },
      description: { required, minLength: minLength(1), maxLength: maxLength(255) },
    },
    newPromocode: {
      codename: { required, minLength: minLength(1), maxLength: maxLength(40) },
      bonus_sum: { required, minValue: minValue(1), maxValue: maxValue(50000) },
      description: { required, minLength: minLength(1), maxLength: maxLength(255) },
      start_dt: { required },
      end_dt: { required }
    },
  },
  computed: {

  },
  watch: {
    selectedGroup: function(val){
      if(val > 0){
        this.getPromocodes(val);
      }
    }
  },
  methods: {
    showDeleteGroupPopup(){
      this.deleteGroupPopup.show = true;
    },
    closeDeleteGroupPopup(){
      this.deleteGroupPopup.show = false;
    },
    deleteGroupPopupSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('id', this.selectedGroup);

      axios
          .post(`/v1/localization/delete-group-promocode`, formData)
          .then(() => {
            this.$store.dispatch("stopPreloader");
            this.closeDeleteGroupPopup();
            this.selectedGroup = 0;
            this.promoCodes = [];
            this.getGroups();
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    showAddGroupPopup(){
      this.addGroupPopup.show = true;
    },
    closeAddGroupPopup(){
      this.addGroupPopup.show = false;
    },
    showDeletePromocodePopup(promocode_id){
      this.deletedPromocodeId = promocode_id;
      this.deletePromocodePopup.show = true;
    },
    closeDeletePromocodePopup(){
      this.deletePromocodePopup.show = false;
    },
    deletePromocodePopupSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('id', this.deletedPromocodeId);

      axios
          .post(`/v1/localization/delete-promocode`, formData)
          .then(() => {
            this.$store.dispatch("stopPreloader");
            this.closeDeletePromocodePopup();
            this.getPromocodes(this.selectedGroup);
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    addGroupPopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newGroup.$touch();
      if(!this.$v.newGroup.$invalid){
        this.$store.dispatch("startPreloader");
        let formData = new FormData();
        formData.append('codename', this.newGroup.codename);
        formData.append('description', this.newGroup.description);

        axios
            .post(`/v1/localization/set-group-promocode`, formData)
            .then(() => {
              this.$store.dispatch("stopPreloader");
              this.closeAddGroupPopup();
              this.newGroup.codename = '';
              this.newGroup.description = '';
              this.getGroups();
              this.submitted = false;
            })
            .catch(err => {
              this.submitted = false;
              this.$store.dispatch("stopPreloader");
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            }); 
      }
    },

    showAddPromocodePopup(){
      this.addPromocodePopup.show = true;
    },
    closeAddPromocodePopup(){
      this.addPromocodePopup.show = false;
    },
    addPromocodePopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newPromocode.$touch();
      if(!this.$v.newPromocode.$invalid){
        this.$store.dispatch("startPreloader");
        let formData = new FormData();
        formData.append('promo_codes_group_id', this.selectedGroup);
        formData.append('codename', this.newPromocode.codename);
        formData.append('bonus_sum', this.newPromocode.bonus_sum);
        formData.append('description', this.newPromocode.description);
        formData.append('is_work_after_first_pay', this.newPromocode.is_work_after_first_pay);
        formData.append('start_dt', formatter.jsObjectToPhp(this.newPromocode.start_dt));
        formData.append('end_dt', formatter.jsObjectToPhp(this.newPromocode.end_dt));

        axios
            .post(`/v1/localization/set-promocode`, formData)
            .then(() => {
              this.$store.dispatch("stopPreloader");
              this.closeAddPromocodePopup();
              this.newPromocode.codename = '';
              this.newPromocode.bonus_sum = 0;
              this.newPromocode.description = '';
              this.newPromocode.is_work_after_first_pay = 0;
              this.newPromocode.start_dt = '';
              this.newPromocode.end_dt = '';
              this.getPromocodes(this.selectedGroup);
              this.submitted = false;
            })
            .catch(err => {
              this.submitted = false;
              this.$store.dispatch("stopPreloader");
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            }); 
      }
    },
    getPromocodes(group_id){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('group_id', group_id);

      axios
          .post(`/v1/localization/get-promocodes`, formData)
          .then(resp => {
            this.$store.dispatch("stopPreloader");
            this.promoCodes = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getGroups(){
      this.$store.dispatch("startPreloader");
      axios
          .get(`/v1/localization/get-group-promocodes`)
          .then(resp => {
            this.$store.dispatch("stopPreloader");
            this.groups = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    phpDatetimeToString(datetime){
      return formatter.phpDateTimeToShortString(datetime);
    }
  },
  created() {
    this.getGroups();
  }
};
</script>

<style scoped lang="scss">
  
</style>